import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><em parentName="h2">{`Pasiunile devin foarte mari din începuturi foarte mici`}</em></h2>
    <h5>{`Ar trebui să scriu câteva cuvinte despre mine aici, dar nu am inspirație, deci voi pune niște citate care mi-au placut.`}</h5>
    <p><em parentName="p">{`Arguing that you don't care about the right to privacy because you have nothing to hide is no different than saying you don't care about free speech because you have nothing to say.`}</em>{` - Edward Snowden`}</p>
    <p><em parentName="p">{`Sleep irregularity is the only regular thing in my life.`}</em>{` - Mudahar Anas`}</p>
    <p><em parentName="p">{`When you die and your whole life flashes before your eyes, how much of it do you want to not have ray tracing?`}</em>{` - Tom's Hardware`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      