import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Proiecte`}</h2>
    <ProjectCard title="Mate-Romania" link="https://sabinm1.github.io/MateRO/" bg="linear-gradient(to right, #8A2387 0%, #F27121 100%)" mdxType="ProjectCard">
Website creat pentru a ajuta elevii la unele formule simple și plictisitoare.
    </ProjectCard>
    <ProjectCard title="counting_sort" link="https://github.com/sabinM1/counting_sort" bg="linear-gradient(to right, #16BFFD 0%, #CB3066 100%)" mdxType="ProjectCard">
Explicație și implementare a metodei de sortare Counting Sort în C++.
    </ProjectCard>
    <ProjectCard title="web_MAX7219" link="https://sabinm1.github.io/web_MAX7219/" bg="linear-gradient(to right, #ff4b1f 0%, #1fddff 100%)" mdxType="ProjectCard">
Proiect simplu pentru a controla una sau mai multe matrici LED, folosind librăria MD_MAX72XX.
    </ProjectCard>
    <ProjectCard title="8424432925592889..." link="https://github.com/sabinM1/8424432925592889329288197322308900672459420460792433" bg="linear-gradient(to right, #BA5370 0%, #F4E2D8 100%)" mdxType="ProjectCard">
Program în Python 3 pentru a arăta tipare care par să fie constante, dar nu sunt.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      