import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Interesat?`}</h2>
    <p>{`Mă poți contacta pe `}<a parentName="p" {...{
        "href": "https://www.reddit.com/user/sabin_M1"
      }}>{`Reddit`}</a>{` și îmi poți vedea mai multe proiecte pe `}<a parentName="p" {...{
        "href": "https://github.com/sabinM1"
      }}>{`GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      